<ng-template #template>
  <a class="close-button cursor-btn" (click)="chooseNextMove()" id="dialog-close-btn">
    <div class="countrer-close">
      <div class="child counter-part" *ngIf="_properties.countdown" [ngClass]="{ hidden: closeBtnVisible }">
        <app-countdown [close]="true" [countdown]="_properties.countdown" (complete)="chooseNextMove()"></app-countdown>
      </div>
    </div>
  </a>
  <div class="modal-body" *ngIf="activeTextBubble">
    <div class="title-flex">
      <img src="/assets/icons/light-bulb.svg" class="light-bulb" alt="light-bulb" />

      <div class="title2 title-space" [innerHTML]="activeTextBubble.prefix"></div>
    </div>

    <div class="title3" [innerHTML]="activeTextBubble.info"></div>

    <div class="center">
      <button
        [disabled]="!canContinue"
        *ngIf="_properties.dialogBtnText"
        #actionButton
        id="actionButton"
        class="btn btn-primary btn-lg"
        (click)="moveNext()"
        [innerHTML]="_properties.dialogBtnText"
      ></button>
    </div>
  </div>
</ng-template>
<div *ngIf="_properties && _properties.options" class="panel scrollable" #container>
  <div *ngIf="notValid" class="nice-error"><em class="icon-exclamation-circle"></em> Choose one please?</div>
  <div class="row centered" *ngIf="_properties.layoutType !== 'text + images' && _properties.layoutType !== 'text-vertical'">
    <div class="col-{{ 12 / _properties.columnType }} form-check" *ngFor="let option of _properties.options; let i = index">
      <a name="OPTION_{{ i }}"></a>
      <input
        class="form-check-input check-lg"
        [checked]="selectedIndex === i"
        type="radio"
        [name]="'option_index_' + i"
        [id]="'option_index_' + i"
        (click)="clickProxy($event, i)"
        [ngClass]="{ hideme: _properties.layoutType !== 'text' }"
      />
      <label class="flex-label form-check-label text-lg" [for]="'option_index_' + i" *ngIf="_properties.layoutType === 'text'">
        <span>{{ _properties.options[i].label }}</span>

        <div *ngIf="_properties.options[i].free" (click)="clickProxy($event, i)">
          <input type="text" class="form-control" [disabled]="selectedIndex !== i" [(ngModel)]="_properties.options[i].other" (ngModelChange)="clickProxy($event, i)"  />
        </div>
      </label>
    </div>
  </div>

  <div *ngIf="_properties.layoutType === 'text + images'" class="row centered-wide">
    <div class="col form-check" *ngFor="let option of _properties.options; let i = index" (click)="clickProxy($event, i)">
      <table class="box-item" [ngClass]="{ selected: EnteredValue === _properties.options[i].label, unselected: EnteredValue !== _properties.options[i].label }">
        <tr>
          <td style="vertical-align: top">
            <span class="prefix">
              {{ _properties.options[i].prefix }}
            </span>
          </td>
          <td><img [src]="_properties.options[i].image_url" [alt]="_properties.options[i].label" /></td>
          <td class="label">
            {{ _properties.options[i].label }}
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div *ngIf="_properties.layoutType === 'text-vertical'" class="row">
    <div class="col form-check" *ngFor="let option of _properties.options; let i = index" (click)="clickProxy($event, i)">
      <div class="col center">
        <input
          class="form-check-input form-check-input-vertical check-lg"
          [checked]="EnteredValue === _properties.options[i].label"
          type="radio"
          [name]="'option_index_' + i"
          [id]="'option_index_' + i"
          (click)="clickProxy($event, i)"
        /><br />

        <label class="vertical-label" [for]="'option_index_' + i"
          ><span>{{ _properties.options[i].label }}</span>
        </label>
      </div>
    </div>
  </div>

  <div class="center" *ngIf="_properties.text1">
    <button [disabled]="!canContinue" #actionButton id="actionButton" class="btn btn-primary btn-lg" (click)="moveNext()" [innerHTML]="_properties.text1"></button>
  </div>
</div>
